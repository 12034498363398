import * as React from "react";

import { Carousel } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import FaMapMarker from "../images/faMapMarker";
import FaBed from "../images/faBed";
import FaBath from "../images/fabath";
import FaArea from "../images/faarea";
import "./CountryList.scss";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Helmet from "react-helmet";

export const query = graphql`
    query SingleCountryRealEstateQuery($slug: String!)  {
       graphCmsItemCountry(countrySlug: { eq: $slug }) {

                    
                        id 
                        countryName
                        countrySlug
                        countryGalleryImages {    
                            id
                            imageAltText 
                            imagePhoto  {
                                gatsbyImageData(placeholder: BLURRED, quality: 60)
                            }
                        }
                        countryHeaderImage {
                            id
                            imageAltText
                            imagePhoto {   
                                gatsbyImageData(placeholder: BLURRED, quality: 60)
                                url
                                 
                            }        
                        }
                        countryImage {
                            id
                            imageAltText
                            imagePhoto { 
      
                              gatsbyImageData(placeholder: BLURRED, quality: 60)
                                           
                            }
                          }
                        
                        countryDescription {
                            text 
                        
                            raw
                            html
                        }
                        itemRealEstates {      
                            id
                            slug
                            listingTitle
                            listingImage {
                                imagePhoto {
                                    gatsbyImageData(placeholder: BLURRED, quality: 60)
                                }
                            }
                            listingLocation
                            listingStyle
                            listingPriceRange 
                            listingBathroomCount
                            listingBedroomCount   
                            listingDescription {
                                text 
                        
                                raw
                                html
                            }
                        }  
            
                
            

        }
      }
    `;

const CountryRealEstateList = ({ data, index }) => {
  const destination = data.graphCmsItemCountry;
  const options = {
    caption: { showCaption: false, },
    arrows: { showArrows: true, },
    status: { showStatus: true, },
    indicators: { showIndicators: true, },
    inloop: { infiniteLoop: true, },
    thumbs: { showThumbs: true, },
    keyarrows: { useKeyboardArrows: true, },
    aplay: { autoPlay: true, },
    hover: { stopOnHover: true, },
    swipeable: { swipeable: true, },
    height: { dynamicHeight: true, },
    touch: { emulateTouch: true, },
    focus: { autoFocus: false, },
    selected: { selectedItem: 0, },
    intv: { interval: 2000, },
    transtime: { transitionTime: 500, },
  };

  return (
    <Layout>
      <Seo
        title={
          "Experience " +
          destination.countryName +
          " with Royal Palm Realty & Travel"
        }
        description={
          destination.countryDescription.text?.substring(0, 155) || " "
        }
        image={destination.countryImage.imagePhoto.url}
      />
      <Helmet>
        <meta
          property="og:image"
          content={destination.countryImage.imagePhoto.url}
        />
        <meta
          property="twitter:image"
          content={destination.countryImage.imagePhoto.url}
        />
      </Helmet>
      <div className="country-list">
        <div className="header-image">
          <div className="header-overlay"></div>

          <Carousel fade>
            {(destination.countryGalleryImages || []).map((galleryMap, id) => (
              <Carousel.Item>
                <GatsbyImage
                  image={galleryMap.imagePhoto.gatsbyImageData}
                  alt={galleryMap.imageAltText}
                />
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="header-text">
            <h1>{destination.countryName}</h1>
            <p id="desc">{destination.countryDescription.text}</p>
          </div>
        </div>

        <div className="listings">
          <div className="listings-content">

            {/* DESKTOP */}
            <div className="featured-listings desktop">
              {(destination.itemRealEstates || []).map((block, index) => (
                <div className="data-wrapper" key={index}>
                  <Link
                    key={block.slug}
                    to={`/${destination.countrySlug}/${block.slug}`}
                  >
                    <div className="item-box">
                      <div id="image-field">
                        <GatsbyImage
                          image={block.listingImage.imagePhoto.gatsbyImageData}
                          key={index}
                          alt={block.listingImage.imageAltText}
                        />
                        {block.listingPriceRange && (
                            <div className="price-wrapper">
                            <div id="price">
                            <p>{block.listingPriceRange}</p>
                            </div>
                            </div>
                        )}
                      </div>
                      <div className="text-box">
                        <span className="title" key={index}>
                          {" "}
                          {block.listingTitle}
                        </span>
                        <div className="primary-info">
                          <span className="type">
                            {block.listingStyle.replace(/_/g, " ")}
                          </span>
                          <br />
                          <span className="location">
                            <FaMapMarker /> <p>{block.listingLocation}</p>
                          </span>
                        </div>
                      </div>
                      <ul className="secondary-info">
                        <li>
                          <FaBed /> {block.listingBedroomCount}
                          <br /> <span>Bedrooms</span>
                        </li>
                        <li>
                          <FaBath /> {block.listingBathroomCount}
                          <br />
                          <span> Bathroom</span>
                        </li>
                        <li id="last">
                          <FaArea /> <br />{" "}
                          <span>
                            Area in ft<sup>2</sup>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Link>
                </div>
              ))}
            </div>

            {/* MOBILE */}
            <div className="featured-listings mobile">
              {(destination.itemRealEstates || []).map((block, index) => (
                <div className="data-wrapper" key={index}>
                  <Link
                    key={block.slug}
                    to={`/${destination.countrySlug}/${block.slug}`}
                  >
                    <div className="item-box">
                      <ul class="mobile-content">
                        <li>
                          <div id="image-field">
                            <GatsbyImage
                              image={
                                block.listingImage.imagePhoto.gatsbyImageData
                              }
                              alt={block.listingImage.imageAltText}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="text-box">
                            <span className="title"> {block.listingTitle}</span>
                            {block.listingPriceRange && (
                              <div id="price">
                                <p>{block.listingPriceRange}</p>
                              </div>
                            )}
                            <div className="primary-info">
                              <span className="type">
                                {block.listingStyle.replace(/_/g, " ")}
                              </span>
                              <br />
                              <span className="location">
                                <FaMapMarker />
                                {block.listingLocation}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CountryRealEstateList;
